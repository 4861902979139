import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Box,
  Typography,
  TextField,
  DialogContent,
  Grid,
  FormHelperText,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SuiButton from 'components/SuiButton';
import DeleteIcon from 'Container/Icons/DeleteIcon';
import SuiInput from 'components/SuiInput';
import ResponsiveDialogWithTable from 'components/DialogBoxWithTable';
import EditIcon from 'Container/Icons/EditIcon';
import { useParams } from 'react-router-dom';
import ErrorMessageComponent from 'components/FormikErrorMessage';
import useSuiSnackbar from 'hooks/useSuiSnackbar';
import SuiDialog from "components/SuiDialog";
const initialRows = [
  {
    isPushed: false,
    questionId: 1,
    questionName: '',
    answerType: '',
    referenceParameterId: '',
    name: '',
    paramValue: [],
    questionToHit: '',
  },
  {
    isPushed: false,
    questionId: 2,
    questionName: '',
    answerType: 'End_Chat',
    referenceParameterId: '',
    name: '',
    paramValue: [],
    questionToHit: '',
  },
]
export default function TemplateFormTable({ templateData, referencePara, formik, loginType, answersData, planType, intialRowValue }) {
  const { values, setFieldValue, touched, errors } = formik;
  const [rows, setRows] = useState(values.data || []);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [deleteData, setDeleteData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [dialogData, setDialogData] = useState([]);
  const subscriptionPlanType = localStorage.getItem('subscriptionType')
  const [selectedCalender, setSelectedCalender] = useState(null)
  const { id } = useParams();
  useEffect(() => {
    setRows(values.data || []);
  }, [values.data]);
  useEffect(() => {
    if (!id && loginType === 'tenant') {
      setRows(intialRowValue);
      setFieldValue('data', intialRowValue)
    } else if (loginType === 'admin') {
      setRows(initialRows);
      setFieldValue('data', initialRows)
    }
  }, []);

  const handleDragStart = (index) => (event) => {
    if (index === rows.length - 1) return;
    setDraggedRowIndex(index);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (index) => (event) => {
    if (index === rows.length - 1 || draggedRowIndex === rows.length - 1) return; // Prevent dropping if it's the last row
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };
  const handleDrop = (index) => (event) => {
    event.preventDefault();
    if (index === rows.length - 1 || draggedRowIndex === rows.length - 1) return; // Prevent dropping if it's the last row

    const updatedRows = [...rows];
    const [draggedRow] = updatedRows.splice(draggedRowIndex, 1);
    updatedRows.splice(index, 0, draggedRow);

    // Update questionId for all rows
    const newRows = updatedRows.map((row, idx) => ({
      ...row,
      questionId: idx + 1,
      questionToHit: row.questionToHit,
    }));

    setRows(newRows);
    setFieldValue('data', newRows);
    setDraggedRowIndex(null);
  };


  const handleAddRow = () => {
    const newRow = {
      isPushed: false,
      questionId: rows.length + 1, // New row's questionId should be the next sequential number
      questionName: '',
      answerType: '',
      referenceParameterId: '',
      name: '',
      paramValue: [],
      questionToHit: '',
    };

    // Update the rows with the new row added before the last row
    const updatedRows = [
      ...rows.slice(0, -1), // All rows except the last one
      newRow, // New row
      rows[rows.length - 1], // Append the last row at the end
    ];

    // Update the questionId for all rows
    const newRows = updatedRows.map((row, idx) => ({
      ...row,
      questionId: idx + 1,
    }));

    setRows(newRows);
    setFieldValue('data', newRows);
  };


  const validateQuestionToHit = (value, index) => {
    const maxQuestionToHit = rows.length;
    const questionNumber = rows[index].questionId;
    let error = '';

    if (value > maxQuestionToHit) {
      error = 'Question count exceeds the number of rows.';
    } else if (questionNumber === parseInt(value)) {
      error = 'Question to hit must differ from question number.';
    } else if (value < questionNumber) {
      error = 'Question to hit cannot be lesser than the row number.';
    }

    if (error) {
      formik.setFieldError(`data.${index}.questionToHit`, error);
    } else {
      formik.setFieldError(`data.${index}.questionToHit`, '');
    }
    return error;
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    if (value === 'Searchable_Dropdown' || value === 'Selectable_Option') updatedRows[index].paramValue = [];
    const updatedRow = { ...updatedRows[index] };
    if (field === 'questionToHit') {
      const error = validateQuestionToHit(value, index);
      if (!value) {
        updatedRow[field] = '';
      } else {
        updatedRow[field] = Number(value);
      }
    } else {
      updatedRow[field] = value;
    }

    updatedRows[index] = updatedRow;
    setRows(updatedRows);
    setFieldValue('data', updatedRows);
  };
  const suiSnackbar = useSuiSnackbar()
  const newHandleRemove = (index, id, row) => {
    const questionToHitArray = rows?.map(item => item?.questionToHit)
    const questionToHitValues = rows.flatMap(item =>
      item.paramValue.map(param => Number(param.questionToHit))
    );

    if (questionToHitArray?.includes(row.questionId) || questionToHitValues?.includes(row.questionId)) {
      suiSnackbar('Question id is associated with Jump to value')
      return
    }
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);

    if (rows[index].answerType === 'End_Chat') {
      updatedRows.forEach(row => {
        if (row.questionToHit === rows[index].questionId) {
          row.questionToHit = '';
        }
      });
    }

    if (id) {
      let currentQuestionsToDelete = formik.values.questionsToDelete || [];
      let deleteIdArray = [...currentQuestionsToDelete];
      deleteIdArray.push(id);
      formik.setFieldValue('questionsToDelete', deleteIdArray);
    }

    const newRows = updatedRows.map((row, idx) => ({
      ...row,
      questionId: idx + 1,
    }));

    setRows(newRows);
    setFieldValue('data', newRows);
    setOpenDeleteModal(false);
  }

  // const handleRemoveRow = (index, idToDelete) =>  () => {
  //   const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);

  //   if (rows[index].answerType === 'End_Chat') {
  //     updatedRows.forEach(row => {
  //       if (row.questionToHit === rows[index].questionId) {
  //         row.questionToHit = '';
  //       }
  //     });
  //   }

  //   if (id) {
  //     let currentQuestionsToDelete = formik.values.questionsToDelete || [];
  //     let deleteIdArray = [...currentQuestionsToDelete];
  //     deleteIdArray.push(idToDelete);
  //     formik.setFieldValue('questionsToDelete', deleteIdArray);
  //   }

  //   const newRows = updatedRows.map((row, idx) => ({
  //     ...row,
  //     questionId: idx + 1,
  //   }));

  //   setRows(newRows);
  //   setFieldValue('data', newRows);
  // };

  const handleOpenDialog = (index, type) => {

    setCurrentRowIndex(index);
    setTitle(type);
    const data = rows[index]?.paramValue?.length ? rows[index]?.paramValue : [{ id: '', value: '', questionToHit: '' }];
    setOpenModal(true);
    setDialogData(data);

    if (type !== 'Calender' && rows[index].questionToHit) {
      // console.log('herr', rows[index].questionToHit)
      // handleChange(index, 'questionToHit', '')
    }

    if (id && !rows[index]?.paramValue) {
      const cbAnswersData = rows[index].cb_Answers.map(item => item.paramValue);
      setDialogData(cbAnswersData.flat());
      if (!cbAnswersData?.flat().length) {
        setDialogData([{ id: '', value: '', questionToHit: '' }]);
      }
    }

    if (loginType === 'tenant' && !rows[index]?.paramValue) {
      const cbAnswersData = rows[index].cb_Answers.map(item => item.paramValue);
      setDialogData(cbAnswersData.flat());
    }
    if (loginType === 'tenant' && rows[index]?.calendlyIntegrationId) {
      console.log(rows[index]?.calendlyIntegrationId)
      setSelectedCalender(rows[index]?.calendlyIntegrationId)
    }
  };
  const handleDialogClose = (data) => {
    if (data) {
      const updatedRows = [...rows];
      const updatedRow = {
        ...updatedRows[currentRowIndex],
        paramValue: data.map((item) => ({
          id: item.id,
          value: item.value,
          questionToHit: item.questionToHit,
        })),
      };

      updatedRows[currentRowIndex] = updatedRow;
      setRows(updatedRows);
      setFieldValue('data', updatedRows);
    }
    setOpenModal(false);
  };

  const handleDialogCloseCalender = (data) => {
    if (data) {
      const updatedRows = [...rows];
      const updatedRow = {
        ...updatedRows[currentRowIndex],
        calendlyIntegrationId: data,
      };
      console.log(updatedRows)
      updatedRows[currentRowIndex] = updatedRow;
      setRows(updatedRows);
      setFieldValue('data', updatedRows);
    }
    setOpenModal(false);
  };

  const handleInputChange = (index, field, value) => {
    console.log(index);
    console.log(field);
    console.log(value);
    handleChange(index, field, value);
  };

  const isDraggable = (index) => {
    return index !== rows.length - 1;
  };

  const isDropAllowed = (index) => {
    return index !== rows.length - 1 && draggedRowIndex !== rows.length - 1;
  };

  useEffect(() => {
    if (planType && !planType?.includes('calendly')) {
      const updatedRows = rows.map(row => ({
        ...row,
        answerType: row.answerType === 'Calender' ? '' : row.answerType,
      }));
      setRows(updatedRows);
      setFieldValue('data', updatedRows);
    }
  }, [planType]);

  return (
    <><SuiDialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} haveCloseIcon>
      <DialogContent
        sx={{
          fontWeight: "semiBold",
        }}
      >
        Are you sure you want delete this question ?
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Grid container justifyContent={"end"} spacing={2}>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ px: "1rem" }}
                  onClick={() => setOpenDeleteModal(false)}
                  buttonColor="dark"
                  variant="outlined"
                  fullWidth
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item xs={12} sm="auto">
                <SuiButton
                  size="small"
                  sx={{ px: "1rem" }}
                  buttonColor="info"
                  fullWidth
                  onClick={() => newHandleRemove(deleteData?.index, deleteData?.row?.id, deleteData?.row)}
                >
                  Delete
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </SuiDialog><TableContainer
      component={Paper}
      sx={{
        border: '1px solid #e0e0e0',
        overflowY: 'auto',
        // maxHeight: 450
      }}
    >
        <Box
          px={{ xs: 1, md: 2, lg: 3 }}
          py={2}
          sx={{
            borderBottom: '1px solid #D3E0E5',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography fontSize="18px" fontWeight="semiBold" sx={{ margin: '0 24px' }}>
            Template Forms
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, m: 1 }}>
          <SuiButton
            type="button"
            size="small"
            buttonColor="primary"
            sx={{ px: '1rem', borderRadius: '2px !important' }}
            onClick={handleAddRow}
          // disabled={rows.length >= 25 ? true : false}
          >
            Add Q-A Pair
          </SuiButton>
        </Box>
        <Table aria-label="template form table" sx={{ borderTop: 0, minWidth: 800 }}>
          <TableHead sx={{ whiteSpace: 'nowrap' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Data push</TableCell>
              <TableCell>Parameters</TableCell>
              <TableCell>No</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Jump to</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  draggable={isDraggable(index)}
                  onDragStart={isDraggable(index) ? handleDragStart(index) : undefined}
                  onDragOver={isDropAllowed(index) ? handleDragOver(index) : undefined}
                  onDrop={isDropAllowed(index) ? handleDrop(index) : undefined}
                  sx={{ height: '48px' }}
                >
                  <TableCell sx={{ padding: '8px' }}>
                    <DragIndicatorIcon
                      draggable={isDraggable(index)}
                      onDragStart={isDraggable(index) ? handleDragStart(index) : undefined}
                      style={{ cursor: isDraggable(index) ? 'move' : 'default' }} />
                  </TableCell>
                  <TableCell sx={{ padding: '16px', textAlign: 'left' }}>
                    <Checkbox
                      color="secondary"
                      checked={row.isPushed}
                      style={{ borderRadius: '0 !important' }}
                      sx={{
                        '& svg': {
                          borderRadius: '1px !important',
                        },
                        '& .MuiIconButton-root': {
                          borderRadius: '1px !important',
                        },
                        '&.MuiCheckbox-root': {
                          borderRadius: '1px !important',
                        },
                        '& .css-1me0qvy-MuiButtonBase-root-MuiCheckbox-root': {
                          borderRadius: '1px !important',
                        }
                      }}
                      onChange={() => handleChange(index, 'isPushed', !row.isPushed)} />
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    <Select
                      name={`referenceParameterId${index}`}
                      fullWidth
                      value={row.referenceParameterId === null
                        ? ""
                        : row.cb_ReferenceParameter?.type === 'custom'
                          ? 'custom'
                          : row.referenceParameterId}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiSelect-select": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiSelect-icon": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .css-mlog3m-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                      }}
                      onChange={(e) => handleChange(index, 'referenceParameterId', e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        Select Parameter
                      </MenuItem>
                      {referencePara.map((item) => item.name === 'custom' ? null : (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                      )}
                      <MenuItem value="custom">custom</MenuItem>
                    </Select>
                    {(row.cb_ReferenceParameter?.type === 'custom' || row.referenceParameterId === 'custom') && (
                      <SuiInput
                        placeholder="Enter custom reference parameter"
                        value={row.cb_ReferenceParameter?.name || row.name}
                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                        fullWidth
                        sx={{ marginTop: '8px' }} />
                    )}
                  </TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'center' }}>
                    {row.questionId}
                  </TableCell>

                  <TableCell sx={{ padding: '8px' }}>
                    <SuiInput
                      value={row.questionName}
                      onChange={(e) => handleChange(index, 'questionName', e.target.value)}
                      fullWidth
                      placeholder="Enter Question"
                      onBlur={formik.handleBlur} />
                    {/* <ErrorMessageComponent
              touched={touched?.data?.[index]?.questionName}
              error={formik.errors.data?.[index]?.questionName}
            /> */}
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    <Select
                      value={row.answerType}
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiSelect-select": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .MuiSelect-icon": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                        "& .css-mlog3m-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                          borderRadius: '2px !important',
                          fontSize: 'small !important'
                        },
                      }}
                      onChange={(e) => {
                        handleChange(index, 'answerType', e.target.value);
                        if (e.target.value === 'Searchable_Dropdown' ||
                          e.target.value === 'Selectable_Option'
                          || (e.target.value === 'Calender' && loginType === 'tenant')) {
                          handleOpenDialog(index, e.target.value);
                        }
                      }}
                      displayEmpty
                      disabled={rows.length - 1 === index}
                    >
                      <MenuItem value='' disabled>
                        Select Answer Type
                      </MenuItem>
                      {answersData.map((item) => (
                        <MenuItem key={item.id} value={item.value} disabled={rows.length === index}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <ErrorMessageComponent
              touched={touched?.data?.[index]?.answerType}
              error={formik.errors.data?.[index]?.answerType}
            /> */}
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    <TextField
                      type='number'
                      size='small'
                      value={['End_Chat', 'Selectable_Option', 'Searchable_Dropdown'].includes(row.answerType) ? '' : row.questionToHit}
                      onChange={(e) => handleInputChange(index, 'questionToHit', e.target.value)}
                      disabled={['End_Chat', 'Selectable_Option', 'Searchable_Dropdown'].includes(row.answerType)}
                      inputProps={{
                        onWheel: (event) => event?.target?.blur(),
                        sx: {
                          width: '50px',
                          height: '50px',
                          textAlign: 'center',
                          maxWidth: '50px',
                          border: '1px solid #E0E0E0',
                          borderRadius: '2px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          appearance: 'textfield',
                        },
                        '::-webkit-outer-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                        onKeyDown: (e) => {
                          if (['e', 'E', '+', '-', '.'].includes(e.key)) {
                            e.preventDefault();
                          }
                        },
                        '::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }} />
                  </TableCell>
                  <TableCell sx={{ padding: '8px' }}>
                    {loginType === 'tenant'
                      ? ['Searchable_Dropdown', 'Selectable_Option', 'Calender'].includes(row.answerType) && (
                        <IconButton color='secondary' onClick={() => handleOpenDialog(index, row.answerType)}>
                          <EditIcon />
                        </IconButton>
                      )
                      : ['Searchable_Dropdown', 'Selectable_Option'].includes(row.answerType) && (
                        <IconButton color='secondary' onClick={() => handleOpenDialog(index, row.answerType)}>
                          <EditIcon />
                        </IconButton>
                      )}

                    {rows?.length > 2 && index !== rows.length - 1 ? (
                      <IconButton color='secondary'
                        onClick={() => {
                          setDeleteData({ index, id: row?.id, row })
                          setOpenDeleteModal(true);
                          // handleRemoveRow(index, row.id); 
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <span></span>
                    )}

                  </TableCell>
                </TableRow>
                {((touched?.data?.[index]?.questionName && errors?.data?.[index]?.questionName) || (touched?.data?.[index]?.answerType && errors?.data?.[index]?.answerType) || errors?.data?.[index]?.questionToHit) && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        fontSize: ".9em",
                        color: "#FC3C3C",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}> {errors?.data?.[index]?.questionName} </TableCell>
                    <TableCell
                      sx={{
                        fontSize: ".9em",
                        color: "#FC3C3C",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}> {errors?.data?.[index]?.answerType} </TableCell>
                    <TableCell sx={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}>
                      {errors?.data?.[index]?.questionToHit}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <ResponsiveDialogWithTable
          open={openModal}
          handleClose={handleDialogClose}
          title={title}
          initialData={dialogData}
          formik={formik}
          rowData={rows}
          currentRowIndex={currentRowIndex}
          handleSaveCalender={handleDialogCloseCalender}
          selectedCalender={selectedCalender}
          setSelectedCalender={setSelectedCalender} />
      </TableContainer></>
  );
}
