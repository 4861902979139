import { removeTags } from "helper";
import moment from "moment";
import * as yup from "yup";
const SUPPOERTED_IMAGE = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];
const numericOnly = /^\d+$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const onlyAlphabet = /^[a-zA-Z\s-]+$/;
const emailPattern = /^(?!\s)(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?!\s)$/i;
const passWordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[\w@$!%*#?&]{7,20}$/;
const passwordPattern =
  /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g;
const minCr = 3;
// const urlPattern = '^[a-zA-Z0-9][a-zA-Z0-9-_.]{0,61}[a-zA-Z0-9]{0,1}\\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\\.[a-zA-Z]{2,3})$';
// const urlPattern = '(www?://)?(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
const urlPattern = /^(?!\s)(?:[a-zA-Z]+:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/
const spaceRegex = new RegExp(/(^\s)|(\s$)|(^\-)|(\-$)|(^_)|(_$)/);
// const passwordPatterns = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(provideFile);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;

        resolve(imgDimensions);
      };
    };
  });
};
export const imageDimensionCheck = yup.addMethod(
  yup.mixed,
  "imageDimensionCheck",
  function (message, requiredWidth, requiredHeight, targetRatio, isDimension) {
    return this.test("image-width-height-check", message, async function (value) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      if (typeof value !== "object" && value.split("/")[0] === "https:") {
        return true;
      }
      if (!SUPPOERTED_IMAGE.includes(value.type)) {
        return createError({
          path,
          message,
        });
      }
      if (value.size >= 1048576 * 1) {
        return createError({
          path,
          message: "Max allowed size is less then 1MB",
        });
      }
      const imgDimensions = await imageWidthAndHeight(value);
      if (
        imgDimensions.width <= requiredWidth &&
        imgDimensions.height <= requiredHeight &&
        isDimension
      ) {
        return createError({
          path,
          message: `The image dimension should be ${requiredWidth}px to ${requiredHeight}px!`,
        });
      }

      return true;
    });
  }
);

const password = {
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordRegex,
      "Password must contains at least 7 characters, one number, one alphabet, one special character & cannot exceed 20 characters."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password does not match."),
};

export const loginWithPasswordSchema = yup.object({
  email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Wrong Email Id")
    .matches(/^\S.*\S$|^\S$/, 'Email ID cannot have leading or trailing spaces.')
  ,
  password: yup
    .string()
    .required("Password cannot be blank.")
  // .matches(
  //   passwordRegex,
  //   "Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces."
  // )
});
export const changePasswordSchema = yup.object({
  new_password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      passWordValidation,
      "Password must contains at least 7 characters, one number, one alphabet, and one special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("new_password"), null], "Password entries do not match."),
});
export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Enter valid Email ID."),
});
export const AssignDIDschema = yup.object({
  customer_name: yup.string().required("Customer Name cannot be blank."),
  did_num: yup
    .string()
    .required("DID Number cannot be blank.")
    .matches(/^[0-9]+$/, " Special characters and alphabets are not allowed.")
    .test("len", "Minimum 7 and maximum 15 numbers are allowed.", (val) =>
      val ? val.length >= 7 && val.length <= 15 : true
    ),

  price: yup.string().required("DID Number Price cannot be blank."),
});
export const pageSchema = yup.object({
  page_content: yup.string().required("Page Content cannot be blank."),
});

export const updateCustomerSchema = yup.object({
  cus_username: yup
    .string()
    .required("Name cannot be blank.")
    .min("2", "Minimumn 2 character requried")
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed.")
    .test("len", "Username cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_email: yup
    .string().trim('No leading or trailing spaces are allowed')
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Email ID.")
    .max(100, 'Minimum 6 and maximum 100 characters allowed')
    .min(6, 'Minimum 6 and maximum 100 characters allowed')
  ,
  cus_role_id: yup
    .string()
    .required("Role cannot be blank."),
  cus_password: yup
    .string()
    .matches(
      passwordRegex,
      'Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces.'
    ),
  cus_confirm_password: yup
    .string()
    .oneOf([yup.ref("cus_password"), null], "Password entries do not match.")
    .nullable(),
});

/* createCustomerSchema */
export const createCustomerSchema = yup.object({
  cus_username: yup
    .string()
    .required("Name cannot be blank.")
    .min("2", "Minimumn 2 character requried")
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed.")
    .test("len", "Username cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID.")
    .max(100, 'Maximum 100 characters allowed')
    .min(6, 'Minimum 6 characters allowed')
    .test('no-leading-trailing-spaces', 'Email cannot have leading or trailing spaces', value => {
      if (value !== undefined) {
        new RegExp(/(^\s)|(\s$)|(^\-)|(\-$)|(^_)|(_$)/).test(value)
      }
      return true;
    }),
  cus_role_id: yup
    .string()
    .required("Role cannot be blank."),
  cus_password: yup
    .string()
    .required('Password cannot be blank')
    .matches(
      passwordRegex,
      'Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces.'
    ),
  cus_confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("cus_password"), null], "Password entries do not match.")
  // .nullable(),
});

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      // "Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces."
      "New Password must contain at least 7 characters with at least one uppercase letter and one number."
    )
    .nullable(),

  confirm_password: yup
    .string()
    .required("Confirm New Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password entries do not match.")
    .nullable(),
});
export const importCSV = yup.object({
  file: yup
    .mixed()
    .required("File cannot be blank.")
    .test("fileSize", "File size cannot be greater then 2MB.", (value) => {
      if (!value) return true;
      return value.size <= 2 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
      ];
      return supportedFormats.includes(value.type);
    }),
});
export const createAdmin = yup.object({
  admin_role1: yup.string().required("Admin Role cannot be blank."),
  Email_id: yup
    .string()
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  admin_name: yup
    .string()
    .required("First Name cannot be blank.")
    .test("len", "First Name should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    ),
  Last_Name: yup
    .string()
    .required("Last Name cannot be blank.")
    .test("len", "Last Name should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    ),
  status_type: yup.string().required("Status cannot be blank."),
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 7 characters, one number, one alphabet, and one special character."
    ),
});

export const UpdateAdmin = yup.object({
  admin_role1: yup.string().required("Admin Role cannot be blank."),
  Email_id: yup
    .string()
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  admin_name: yup
    .string()
    .required("First Name cannot be blank.")
    .test("len", "First Name should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    ),
  Last_Name: yup
    .string()
    .required("Last Name cannot be blank.")
    .test("len", "Last Name should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    ),
  status_type: yup.string().required("Status cannot be blank."),
});
export const spendLimitSchema = yup.object({
  warning_cus_email: yup
    .string()
    .matches(emailPattern, "Invalid Warning Limit Email.")
    .test("len", "Warning Limit Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable() // Allow the field to be null
    .required("Warning Limit Email ID cannot be blank."),

  daily_limit: yup
    .number()
    .notOneOf([0], "Daily limit cannot be 0.")
    .required("Daily limit cannot be blank."),
  weekly_limit: yup
    .number()
    .notOneOf([0], "Weekly limit cannot be 0.")
    .required("Weekly limit cannot be blank."),
  monthly_limit: yup
    .number()
    .notOneOf([0], "Monthly limit cannot be 0.")
    .required("Monthly limit cannot be blank."),
  warning_daily_limit: yup.number().notOneOf([0], "Daily warning limit cannot be blank."),
  warning_monthly_limit: yup.number().notOneOf([0], "Monthly warning limit cannot be blank."),
  warning_weekly_limit: yup.number().notOneOf([0], "Weekly warning limit cannot be blank."),
});
export const forgotPasswordSchema = yup.object({
  password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      passwordRegex,
      // /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{7,20}$/g,
      "Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces"
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password does not match."),
});
export const updatePriceSchema = yup.object({
  global_national_did_prize: yup.string().required("National DID cannot be blank."),
  global_international_did_prize: yup.string().required("International DID cannot be blank."),
  global_sip_prize: yup.string().required("SIP Trunk cannot be blank."),
});
export const passwordChangeSchema = yup.object({
  old_password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 7 characters, one number, one alphabet, and one special character."
    ),
  ...password,
});

export const companySchema = yup.object({
  company_name: yup
    .string()
    .required("Please enter company name.")
    .min(minCr, `Company name must have at least ${minCr} characters.`)
    .max(60, "Company name may not be longer then 60 characters.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_logo: yup.string().required("Please upload a company logo."),
  url: yup.mixed().when("company_logo", {
    is: (image) => image?.split("/")[0] !== "https:",
    then: yup
      .mixed()
      .imageDimensionCheck(
        "Please upload company logo in jpg, jpeg, svg or png format.",
        120,
        120,
        1,
        true
      ),
    otherwise: yup.mixed().notRequired(),
  }),
  company_industry_type: yup.string().required("Please select an industry type."),
  company_address: yup
    .string()
    .min(minCr, `Company address must have at least ${minCr} characters.`)
    .max(250, "Company address may not be longer then 250 characters.")
    .nullable(),
});

export const skillSchema = yup.object({
  skill_name: yup
    .string()
    .required("Please enter skill name.")
    .min(minCr, `Skill name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const industrySchema = yup.object({
  industry_name: yup
    .string()
    .required("Please enter industry name.")
    .min(minCr, `Industry name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const jobSchema = yup.object({
  job_designation: yup
    .string()
    .required("Please enter designation.")
    .min(minCr, `Designation name must have at least ${minCr} characters.`)
    .max(60, `Designation name must have at least 60 characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_id: yup.string().required("Please select a company."),
  skills: yup
    .array()
    .min(2, "Please add at least two skill.")
    .required("Please select add skills."),
  job_summary: yup
    .string()
    .required("Please enter Summary.")
    .min(minCr, `Summary name must have at least ${minCr} characters.`)
    .max(150, `Summary must be within 150 Characters`),
  job_description: yup
    .string()
    .required("Please enter job description.")
    .test("contentRequired", "Please enter job description.", (item) => {
      return removeTags(item);
    }),
  extra_benefits: yup.string().nullable(),
  currency: yup.string().required("Please select currency type."),
  salary_offered: yup
    .string()
    .required("Please enter offered salary.")
    .matches(numericOnly, "Must be numeric only."),
  job_type: yup.string().required("Please select a job type."),
  job_mode: yup.string().required("Please select a mode type."),
  experience: yup
    .string()
    .required("Please enter experience.")
    .matches(numericOnly, "Must be numeric only."),
});

export const DateValidateSchema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), "Start date should not be greater than today.")
    .typeError("Please enter valid start date.")
    .nullable(),
  end_date: yup
    .date()
    .max(new Date(), "End date should not be greater than today.")
    .when("start_date", {
      is: (start_date) => moment.isDate(start_date),
      then: yup
        .date()
        .required("Please enter end date.")
        .typeError("Please enter valid end date.")
        .nullable(),
    })
    .test("lessthenSD", "End date should not be less than start date.", (value, testContext) => {
      return !moment(value).isBefore(testContext.parent.start_date);
    })
    .typeError("Please enter valid end date.")
    .nullable(),
});

/* createTenantSchema */

export const createTenantSchema = (id, isCalendly) => yup.object({
  t_name: yup
    .string()
    .required("Name cannot be blank.")
    .min(2, "Minimum 2 characters required")
    .matches(/^[A-Za-z ]+$/, "Special characters and numbers are not allowed.")
    .max(50, "Name cannot exceed more than 50 characters.")
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  t_email: yup
    .string()
    .required("Email cannot be blank.")
    .email("Email must be a valid email address.")
    .min(6, "Minimum 6 and maximum 100 characters allowed")
    .max(100, "Minimum 6 and maximum 100 characters allowed")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email Address"
    )
    .test(
      'no-leading-trailing-spaces',
      'Email should not have leading or trailing spaces',
      value => value ? value.trim() === value : true
    ),
  t_password: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,20}$/,
      "Password must be 7 to 20 characters long, contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not include any spaces."
    )
    .when([], {
      is: () => !id,
      then: yup.string().required('Password is required'),
      otherwise: yup.string(),
    }),
  t_confirm_password: yup
    .string()
    .oneOf([yup.ref("t_password"), null], "Password entries do not match.")
    .when([], {
      is: () => !id,
      then: yup.string().required('Confirm Password is required'),
      otherwise: yup.string(),
    }),
  t_company_name: yup
    .string()
    .required("Company Name cannot be blank.")
    .min(2, "Minimum 2 characters required")
    .max(150, "Company Name cannot exceed more than 150 characters."),
  t_industry_name: yup
    .string()
    .required("Industry Name cannot be blank.")
    .min(2, "Minimum 2 characters required")
    .matches(/^[A-Za-z ]+$/, "Special characters and numbers are not allowed.")
    .max(50, "Industry Name cannot exceed more than 50 characters.")
    .test("len", "Enter the industry name.", (val) => (val ? val.trim().length > 0 : true)),
  t_contact_number: yup
    .string()
    .required("Contact Number cannot be blank.")
    .min(7, "Minimum 7 digits required")
    .matches(/^[0-9]+$/, "Only numbers are allowed.")
    .max(15, "Contact Number cannot exceed more than 15 digits.")
    .test("len", "Enter the contact number.", (val) => (val ? val.trim().length > 0 : true)),
  t_company_address: yup
    .string()
    .required("Company Address cannot be blank.")
    .min(2, "Minimum 2 characters required")
    .max(250, "Company address cannot exceed more than 250 characters.")
    .test("len", "Enter the company address.", (val) => (val ? val.trim().length > 0 : true)),
  t_timezone: yup.string(),
  t_country: yup.string(),
  t_state: yup.string(),
  t_city: yup.string(),
  t_subscription_id: yup
    .string()
    .required("Subscription cannot be blank."),
  calendlyPairs: isCalendly
    ? yup.array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .min(2, 'Minimum 2 characters required')
            .max(50, 'Integration details name cannot exceed more than 50 characters')
            .required('Name is required')
            .matches(/^[A-Za-z0-9 ]+$/, "Special characters are not allowed.")
            .test("len", "Name is required", (val) => (val ? val.trim().length > 0 : true)),
          token: yup.string().required('Access Token is required')
        })
      )
      .min(1, "At least one Calendly integration is required")
    : yup.array().of(
      yup.object().shape({
        name: yup.string(),
        token: yup.string()
      })
    )
});

/* createPlanSchema */
export const createPlanSchema = yup.object({
  planName: yup
    .string()
    .required("Subscription Name cannot be blank.")
    .min("2", "Minimumn 2 character requried")
    .matches(alphaNumeric, "Special characters are not allowed.")
    .test("len", "Name cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  integration: yup
    .mixed()
    .test("integration", "Kindly select at least one integration", (value, context) => {
      return context.parent.xcally || context.parent.calendly;
    }),
  concurrentChatBotSession: yup
    .number()
    .required('Concurrent Chatbot Session cannot be blank')
    .max(10, 'Maximum 10 Concurrent Chatbot Session allowed')
    .min(1, 'Minimum 1 Concurrent Chatbot Session required')
  ,
  aiRequestUsageLimit: yup
    .number()
    .required('AI Request Usage Limit cannot be blank')
    .max(100, 'Maximum 100 AI Request Usage allowed')
    .min(5, 'Minimum 5 AI Request Usage Limit required')
  ,

});

/* updateIntegrationSchema */

export const updateIntegrationSchema = yup.object({
  xcally_url: yup
    .string()
    .matches(urlPattern, "Invalid URL")
    .required('Xcally URL cannot be blank')
  ,
  watson_url: yup
    .string()
    .required('Watson URL cannot be blank')
    .matches(urlPattern
      , "Invalid URL"),
  watson_secret_key: yup
    .string()
    .min("2", "Minimumn 2 character requried")
    .required("Secret key cannot be blank.")
    .test("len", "Watson Secret Key cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter Watson Secret Key.", (val) => (val ? val.trim().length > 0 : true)),
  calendlly_url: yup
    .string()
    .required('Calendly URL cannot be blank')
    .matches(urlPattern, "Invalid URL"),
});


// Create Template
const rowValidationSchema = yup.object().shape({
  isPushed: yup.boolean(),
  questionName: yup.string().required('Required'),
  answerType: yup.string().required('Required'),
  referenceParameterId: yup.string().nullable(),
  name: yup.string().when('referenceParameterId', {
    is: 'custom',
    then: yup.string(),
  }),

  questionToHit: yup.mixed().test('valid-questionToHit', 'Invalid question to hit', function (value) {
    const { questionId } = this.parent;
    const data = this.options.context.data;
    const maxQuestionToHit = data.length;
    if (value === null) {
      return true
    }
    if (value > maxQuestionToHit) {
      return this.createError({ message: 'Jump to value exceeds the number of questions.' });
    }
    else if (value === questionId) {
      return this.createError({ message: 'Jump to value must differ from question number.' });
    } else if (value < questionId) {
      return this.createError({ message: 'Jump to value cannot be lesser than the question number.' });
    }
    return true;
  }),
});
export const createTemplateValidation = yup.object({
  name: yup
    .string()
    .required('Template Name cannot be blank').
    matches(alphaNumeric, 'Special characters are not allowed.')
    .min("2", "Minimumn 2 character requried")
    .max(50, 'Template name cannot exceed more than 50 characters')
  ,
  subscriptionPlanId: yup
    .string()
    .required("Subscription cannot be blank."),
  data: yup.array().of(rowValidationSchema)
  ,
  xcally: yup.boolean(),
  variantKey: yup.string().matches(alphaNumeric, 'Special characters are not allowed.')
    .min("2", "Minimumn 2 character requried")
    .test("len", "Variant Key cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    ).nullable()
});
export const createTenantTemplateValidation = yup.object({
  name: yup
    .string()
    .required('Template Name cannot be blank').
    matches(alphaNumeric, 'Special characters are not allowed.')
    .min("2", "Minimumn 2 character requried")
    .max(50, 'Template name cannot exceed more than 50 characters')
  ,
  // subscriptionPlanId: yup
  //   .string()
  //   .required("Subscription cannot be blank."),
  data: yup.array().of(rowValidationSchema)
  ,
  xcally: yup.boolean(),
  variantKey: yup.string().matches(alphaNumeric, 'Special characters are not allowed.')
    .min("2", "Minimumn 2 character requried")
    .test("len", "Variant Key cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    ).nullable()
});