import {
    Box,
    Card,
    CircularProgress,
    DialogContent,
    FormLabel,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
  } from "@mui/material";
  import SuiBox from "components/SuiBox";
  import SuiButton from "components/SuiButton";
  import SuiInput from "components/SuiInput";
  import EnhancedTable from "components/SuiTable/EnhancedTable";
  import TableToolbar from "components/SuiTable/TableToolbar";
  import { useFormik } from "formik";
  import { TenantChatBotTableHeader, TenantTemplateTableHeader } from "./data";
  import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
  import EditIcon from "Container/Icons/EditIcon";
  import DeleteIcon from "Container/Icons/DeleteIcon";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import SuiDialog from "components/SuiDialog";
  import { useEffect, useState } from "react";
  import SuiTypography from "components/SuiTypography";
  import useNavigateSearch from "hooks/useNavigateSearch";
  import { useDownloadDataMutation} from "app/features/api/AuthApi";
  import { appendZero } from "helper";
  import { format } from "date-fns";
  import { setID } from "app/features/DashboardSlice";
  import { useDispatch, useSelector } from "react-redux";
  import { useCustomerBulkDiscountMutation } from "app/features/api/CustomerApi";
  import useSuiSnackbar from "hooks/useSuiSnackbar";
  import * as Yup from "yup";
  import CommonPagination from "components/SuiTablePagination/CommonPagination";
  import UpArrow from "Container/UpArrow";
  import { useDeleteTemplateMutation } from "app/features/api/AuthApi";
  import SearchIcon from '@mui/icons-material/Search';
  import DialogBoxForEncryptedCode from "components/DialogBoxEncryptedCode";
  import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
  import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
  import { useGetTenantTemplateInfoMutation } from "app/features/api/AuthApi";
import { Padding } from "@mui/icons-material";
  export let apiController = new AbortController();
  
  const TenantTemplateTable = () => {
    const { id } = useParams();
    const [count, setCount] = useState(0);
    const suiSnackbar = useSuiSnackbar();
    const [checkedItems, setCheckedItems] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [customerInfo, setCustomerInfo] = useState("");
    const [searchParams, setSearchParams] = useState('')
    const [openCodePreview, setOpenCodePreview] = useState(false)
    const [previewCodeDialogText, setPreviewCodeDialogText] = useState('')
    let page = new URLSearchParams(window.location.search).get("page");
    if (page === null) {
      page = 1;
    }
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(true)
    const [open, setOpen] = useState(false);
    const [getTenantTemplateInfo, { isLoading }] = useGetTenantTemplateInfoMutation();
    const [downloadData] = useDownloadDataMutation();
    const [currentPage, setCurrentPage] = useState(1);
    const [customerBulkDiscount] = useCustomerBulkDiscountMutation();
    const [deleteTemplate, { isLoading: deleteLoader }] = useDeleteTemplateMutation();
    const [paginationObject, setPaginationObject] = useState({
      currentPage: 0,
      resultsPerPage: 0,
      totalPages: 0,
      totalTemplates: 0
    })
    const { permission } = useSelector((state) => state.auth)
    const loginType = localStorage.getItem('type')
    // useEffect(() => {
    //   if(permission && loginType === 'admin' &&!permission?.includes("chatbot:read")) {
    //     navigate('/profile')
    //   }
    // },[permission])
    const doCancel = () => {
      apiController.abort();
      apiController = new AbortController();
    };
  
    function getPageRangeText(paginationInfo) {
      const { currentPage, resultsPerPage, totalTemplates } = paginationInfo;
      const startIndex = (currentPage - 1) * resultsPerPage + 1;
      let endIndex = currentPage * resultsPerPage;
      if (endIndex > totalTemplates) {
        endIndex = totalTemplates;
      }
      const text = `Showing ${startIndex} to ${endIndex} of ${totalTemplates} entries`;
  
      return text;
    }
    const type = localStorage.getItem('type')
  
    const dispatch = useDispatch();
  
    const [openModal, setOpenModal] = useState(false);
    const [deleteID, setDeleteID] = useState("");
    const deleteModal = (id) => {
      setDeleteID(id);
      setOpenModal(true);
    };
    const handleDeleteIconClick = async (id) => {
      try {
        const response = await deleteTemplate(id).unwrap();
        if (response.statusCode === 200) {
          suiSnackbar(response.message, "success");
          setOpenModal(false);
          navigate('/chatbot-template')
          const rolesInfo = await getTenantTemplateInfo( {id, params:{page: 1, limit: 10}})
          setSearchParams('')
          setCount(rolesInfo?.data?.data?.pagination?.totalTemplates);
          setCustomerInfo(rolesInfo?.data?.data?.templates);
          setPaginationObject(rolesInfo?.data?.data?.pagination)
        }
      } catch (error) {
        console.log(error);
      }
    }
    const scriptSRC = process.env.REACT_APP_BASE_URL
    const handleCopyCode = (data) => {
      const textToCopy = `<script src="${scriptSRC}/tenant/main.js/${data}" ></script>`
      navigator.clipboard.writeText(textToCopy);
      suiSnackbar('Code copied successfully', 'success')
    }
    useEffect(() => {
      // This code runs when the component mounts
  
      return () => {
        var tags = document.getElementsByTagName('script');
        for (var i = tags.length; i >= 0; i--){ //search backwards within nodelist for matching elements to remove
          console.log(tags[i]?.getAttribute('id'));
          if (tags[i] && tags[i].getAttribute('id') === 'socket-script') {
            console.log('in script');
            tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
          }
        }
        // This code runs when the component is about to unmount
        var cbwBody = document.querySelector(".cbw__body");
        if(cbwBody) {
          console.log(cbwBody);
          cbwBody.style.display = 'none';
          cbwBody.classList.remove('show');
        }
      };
    }, []); // Empty dependency array means it runs only on mount and unmount
  
  
    // FORM DETAIL
    const formik = useFormik({
      initialValues: {
        cus_account_number: "",
        first_name: "",
        last_name: "",
        mobile_num: "",
        email: "",
        account_type: "",
        account_status: "",
        cus_company_name: "",
      },
  
      enableReinitialize: true,
      onSubmit: () => {
        // handleSubmitForm();
      },
    });
  
    const discountFormik = useFormik({
      initialValues: {
        sip_trunk: "",
        sip_trunk_margin: 0,
        international_did: "",
        international_did_margin: 0,
        national_did: "",
        national_did_margin: 0,
      },
      // enableReinitialize: true,/
      validationSchema: Yup.object().shape({
        sip_trunk: Yup.string().required("Please select the discount type"),
        international_did: Yup.string().required("Please select the discount type"),
        national_did: Yup.string().required("Please select the discount type"),
        sip_trunk_margin: Yup.number().when("sip_trunk", {
          is: (value) => value && value.toLowerCase() === "percentage",
          then: Yup.number()
            .typeError("Please enter a number")
            .min(1, "SIP Trunk value should be at least 1%.")
            .max(100, "SIP Trunk value should not exceed 100%."),
          otherwise: Yup.number()
            .typeError("Please enter a number")
            .min(1, "SIP Trunk value must be at least 1"),
        }),
        international_did_margin: Yup.number().when("international_did", {
          is: (value) => value && value.toLowerCase() === "percentage",
          then: Yup.number()
            .typeError("Please enter a number")
            .min(1, "International DID value should be at least 1%")
            .max(100, "International DID value should not exceed 100%."),
          otherwise: Yup.number()
            .typeError("Please enter a number")
            .min(1, "International DID value must be at least 1"),
        }),
        national_did_margin: Yup.number().when("national_did", {
          is: (value) => value && value.toLowerCase() === "percentage",
          then: Yup.number()
            .typeError("Please enter a number")
            .min(1, "National DID value should be at least 1%")
            .max(100, "National DID value should not exceed 100%."),
          otherwise: Yup.number()
            .typeError("Please enter a number")
            .min(1, "National DID value must be at least 1"),
        }),
      }),
      onSubmit: (values) => {
        handleDiscount(values);
      },
    });
    const handlePageChange = async (event, page) => {
      setIsChecked(true)
      setCurrentPage(page);
      const response = await getTenantTemplateInfo({ id, params: {search: searchParams, page: page, limit: 10}})
      setCustomerInfo(response?.data?.data?.templates)
      setCount(response?.data?.data?.pagination?.totalTemplates);
      setPaginationObject(response?.data?.data?.pagination)
      setPerPage(10);
      setCurrentPage(page);
      setIsChecked(false)
    };
    const downloadDetails = async () => {
      let urlParamss = {};
      if (formik.values.cus_account_number) {
        urlParamss.cus_account_number = formik.values.cus_account_number;
      }
      if (formik.values.first_name) {
        urlParamss.cus_firstname = formik.values.first_name;
      }
      if (formik.values.last_name) {
        urlParamss.cus_lastname = formik.values.last_name;
      }
      if (formik.values.mobile_num) {
        urlParamss.cus_phone_number = formik.values.mobile_num;
      }
      if (formik.values.email) {
        urlParamss.cus_email = formik.values.email;
      }
      if (formik.values.cus_company_name) {
        urlParamss.cus_company_name = formik.values.cus_company_name;
      }
      if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
        if (formik.values.account_type == "pre_paid") {
          urlParamss.cus_account_type = 1;
        } else if (formik.values.account_type == "post_paid") {
          urlParamss.cus_account_type = 0;
        }
      }
      if (formik.values.account_status === "active") {
        urlParamss.cus_status = 1;
      }
      if (formik.values.account_status === "inactive") {
        urlParamss.cus_status = 0;
      }
      if (formik.values.account_status === "blocked") {
        urlParamss.cus_status = 3;
      }
      if (formik.values.account_status === "restricted") {
        urlParamss.cus_status = 2;
      }
      const response =
        Object.keys(urlParamss)?.length > 0
          ? await downloadData(urlParamss).unwrap()
          : await downloadData().unwrap();
      return response?.data;
    };
  
    const onDownloadFn = async () => {
      const response = await downloadDetails();
      const downloadData = response?.map((item, i) => {
        let cusAccntStatus;
        if (item.cus_network_status === "1") {
          cusAccntStatus = "ACTIVE";
        } else if (item.cus_network_status === "0") {
          cusAccntStatus = "INACTIVE";
        } else if (item.cus_network_status === "3") {
          cusAccntStatus = "BLOCKED";
        } else if (item.cus_network_status === "2") {
          cusAccntStatus = "RESTRICTED";
        }
        return {
          "Sr.no": appendZero(i + 1),
          "USERNUMBER": item?.cus_account_number,
          "EMAIL ID": item?.cus_firstname.charAt(0).toUpperCase() + item?.cus_firstname.slice(1),
          "LAST NAME": item?.cus_lastname.charAt(0).toUpperCase() + item?.cus_lastname.slice(1),
          "ROLE": item?.cus_account_type === "1" ? "PRE PAID" : " POST PAID",
        };
      });
  
      const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
      const csvFormat = [
        csvHeaders && csvHeaders.join(","),
        ...(downloadData || []).map(
          (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
        ),
      ].join("\n");
  
      const blob = new Blob([csvFormat], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Customer List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
  
    const handleOpenPreviewDialogBox = (data) => {
      setPreviewCodeDialogText(data)
      setOpenCodePreview(true)
    }
  
    const rows =
      customerInfo &&
      customerInfo?.map((item) => {
        const isNetworkStatus = item.cus_network_status == 1;
        let accountStatus = "";
        if (item.cus_status == 1) {
          accountStatus = "Active";
        }
        if (item.cus_status == 0) {
          accountStatus = "Inactive";
        }
        if (item.cus_status == 3) {
          accountStatus = "Blocked";
        }
        if (item.cus_status == 2) {
          accountStatus = "restricted";
        }
  
        const cus_id = item;
        const editRedirect = (item) => {
          navigate(`edit-template/${item}`);
        };
        const openPreview = (item) => {
          const loaderDiv = document.createElement('div');
          loaderDiv.id = 'loader';
          loaderDiv.className='loader-overlay';
          loaderDiv.innerHTML = `<div class="loader-d"></div>`
          // loaderDiv.style.position = 'fixed';
          // loaderDiv.style.width = '100%';
          // loaderDiv.style.height = '100%';
          // loaderDiv.style.top = 0;
          // loaderDiv.style.left = 0;
          // loaderDiv.style.animation = 'l5 2s infinite linear alternate;' 
          document.body.appendChild(loaderDiv);
  
          const existingScript = document.getElementById('chatboat-script');
          var tags = document.getElementsByTagName('script');
          for (var i = tags.length; i >= 0; i--){ //search backwards within nodelist for matching elements to remove
            console.log(tags[i]?.getAttribute('id'));
            if (tags[i] && tags[i].getAttribute('id') === 'socket-script') {
              console.log('in script');
              tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
            }
          }
          if (!existingScript) {
              const chatbotScript = document.createElement('script');
              chatbotScript.src = `${process.env.PUBLIC_URL}/chatboat/preview-chatbot.js`; // Load from public folder
              chatbotScript.id = 'chatboat-script';
              document.body.appendChild(chatbotScript);
          } 
            const socketId = 'socket-script';
            const existingSocketScript = document.getElementById(socketId);
              const socketScript = document.createElement('script');
              socketScript.id = socketId;
              setTimeout(() => {
                var cbwBody = document.querySelector(".cbw__chat");
                cbwBody.innerHTML = '';
              }, 1000);
            
              socketScript.innerHTML = '';
              socketScript.innerHTML += ` 
              function loadChat() {
                  var messageInput = document.getElementById('messageInput');
                  messageInput.value = '' ;
                
                  messageInput.style.height =  '26px';
                  var inputBox = document.querySelector(".cbw__footer");
                  inputBox.classList.remove('show');
                  inputBox.classList.add('hide');
                  var sendBtn = document.querySelector('.cbw__send-btn');
                  sendBtn.classList.remove('transform-div');
                  const message = {answer: 'ecosmob#anaweringlegal@chatbotwidget', preview: true};
                  window.ws.send(JSON.stringify(message));
                  var chat = document.querySelector('.cbw__chat');
                  var loaderContainer = document.createElement('div');
                  loaderContainer.className = 'cbw__receiver-msg-box';
                  loaderContainer.id = 'main-loader-' + window.loaderCount ;
                  loaderContainer.innerHTML = \`
                      <p class="cbw__receiver-msg">
                          <span class="loader"></span>
                      </p>
                  \`;
                  chat.append(loaderContainer);
                  setTimeout(scrollToBottom, 100); // Add delay to ensure scrolling works
                  function scrollToBottom() {
                    var chatDiv = document.querySelector('.cbw__chat');
                    chatDiv.scrollTop = chatDiv.scrollHeight;
                    chatDiv.scrollTo(0,chatDiv.scrollHeight);
                  }
  
                
                  var cbwBody = document.querySelector(".cbw__body");
                    var cbwCloseBtn = document.querySelector(".cbw__close-btn");
                    console.log(cbwCloseBtn);
                    if(cbwCloseBtn) {
                        cbwCloseBtn.addEventListener("click", (event) => {   
                          event.preventDefault();
                          // var chat = document.querySelector('.cbw__chat');
                          const getCurrentPreview = document.getElementsByClassName('preview');  
                          if(getCurrentPreview) {
                            for(let i=0;i<getCurrentPreview?.length;i++) {
                              getCurrentPreview[i].style.pointerEvents = 'auto';
                            }
                          } 
                        cbwBody.classList.remove('show');
                        var messageInput = document.getElementById('messageInput');
                        messageInput.value = '' ;
                        messageInput.style.height =  '26px';
                        cbwBody.style.display = 'none';
                        var inputBox = document.querySelector(".cbw__footer");
                        inputBox.classList.remove('show');
                        inputBox.classList.add('hide');
                        var sendBtn = document.querySelector('.cbw__send-btn');
                        sendBtn.classList.remove('transform-div');
                        window.ws.close();
  
                    });
                  }
                }
                window.socketTemplateCode = \`${item?.templateCodeEncrpyt}-preview-admin\`
                window.code = \`${item?.templateCodeEncrpyt}\`;
                window.socketPath = 'wss://chatapi.answeringlegal.com';
                console.log(window.socketPath);
                window.ws = new WebSocket(window.socketPath, window.socketTemplateCode);
                window.ws.onopen = () => {
                  var divToRemove = document.getElementById('loader');
                  if (divToRemove) {
                    // Remove the selected div from the body
                      divToRemove.parentNode.removeChild(divToRemove);
                  }
                  var cbwBody = document.querySelector(".cbw__body");
                  cbwBody.classList.add('show');
                  cbwBody.style.display = 'flex'; 
                  var chat = document.querySelector('.cbw__chat');
                  if(chat) {
                    chat.style.display = 'flex';
                    chat.innerHtml = '';
                  }
                  var chatcalendarDiv = document.querySelector('.cbw__chat__calendar');
                  if(chatcalendarDiv) {
                    chatcalendarDiv.style.display = 'none';
                    chatcalendarDiv.innerHtml = '';
  
                  }
                  const getCurrentPreview = document.getElementsByClassName('preview');
                  if(getCurrentPreview) {
                    for(let i=0;i<getCurrentPreview?.length;i++) {
                      getCurrentPreview[i].style.pointerEvents = 'none';
                    }
                  }
                  window.loaderCount = 1;
                  loadChat();
                };
            
                window.ws.onmessage = (event) => {
                  setTimeout(() => {
                      const message = JSON.parse(event.data);
                      const loaderId = 'main-loader-' + window.loaderCount
                      document.getElementById(loaderId)?.classList?.add('hide');
                      if(message?.question_id) {
                          window.questionInfo = message;
                          nextQuestionPrepare(message);
                      } else if(message?.responseType === 'error') {
                        document.getElementById(loaderId)?.classList?.add('hide');
                        var chat = document.querySelector('.cbw__chat');
                        chat.innerHTML = '';
                        var questionContainer = document.createElement('div');
                        questionContainer.className = '';
                        questionContainer.style.color = 'red';
                        questionContainer.style.textAlign = 'center';
                        questionContainer.style.width = '95%';
                        questionContainer.style.fontSize = '13px';
                        questionContainer.id = 'error';
                        questionContainer.innerHTML += \`<div class="">
                            <p class="">
                            \${message?.question}
                            </p>
                        </div>\`;
                        if(chat) {
                          chat.append(questionContainer);
                        }
                        setTimeout(scrollToBottom, 100);
                        var inputBox = document.querySelector(".cbw__footer");
                        inputBox.classList.remove('show');
                        inputBox.classList.add('hide');
                        var messageInput = document.getElementById('messageInput');
                        messageInput.value = '' ;
                    }
                  }, 1000);
                };
                window.ws.onclose = () => {
                    var chat = document.querySelector('.cbw__chat');
                    chat.innerHTML = '';
                    var questionContainer = document.createElement('div');
                    questionContainer.className = '';
                    questionContainer.style.color = 'grey';
                    questionContainer.style.textAlign = 'center';
                    questionContainer.style.width = '100%';
                    questionContainer.style.fontSize = '13px';
                    questionContainer.id = 'error';
                    questionContainer.innerHTML += \`<div class="">
                        <p class="">
                        Your chat session has ended. If you wish to continue the chat,<a href="javascript:void(0);" class="cbw__restart_btn"> click here.</a>
                        </p>
                    </div>\`
                    if(chat) {
                      chat.append(questionContainer);
                    }
                    var restartBtn = document.querySelector('.cbw__restart_btn');
                    if(restartBtn) {
                        restartBtn.addEventListener("click", () => {
                            loadChat();
                        });
                    }
                    
                    setTimeout(scrollToBottom, 100);
                    var inputBox = document.querySelector(".cbw__footer");
                    inputBox.classList.remove('show');
                    inputBox.classList.add('hide');
                    console.log('Disconnected from WebSocket server');
                };
                window.ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };`
              ;
              setTimeout(() => {
                document.body.appendChild(socketScript);
                const customEvent = new CustomEvent('templeteCodeGetEvent', { detail: item});
                document.dispatchEvent(customEvent);
              }, 1000);
        }
        dispatch(setID(cus_id));
        return (
          <>
            <TableRow>
                { /*<TableCell sx={{minWidth: '170px'}}>
                   <Tooltip title="Preview" arrow className="preview">
                    <IconButton onClick={() => openPreview(item)}>
                      <UpArrow />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              */}
              <TableCell>
                <Tooltip title={item?.templateName || "-"} arrow>
                  <span>{item?.templateName || "-"}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Subscription Code" arrow>
                  <span>
                    {/* {type === 'tenant' ?
                      <IconButton onClick={() => handleCopyCode(item.templateCodeEncrpyt)} >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      :
                      item?.cb_SubscriptionPlan?.subscriptionName || '-'} */}
                      <IconButton onClick={() => handleCopyCode(item.templateCodeEncrpyt)} >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
              {/* <TableCell>
                {type === 'tenant' ? (
                  <>
                    <Tooltip title="Edit" arrow>
                      <IconButton onClick={() => editRedirect(item.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <IconButton onClick={() => deleteModal(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Steps" arrow>
                    <IconButton onClick={() => handleOpenPreviewDialogBox(item.templateCodeEncrpyt)} >
                      <InfoOutlinedIcon />
                    </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {permission ? (
                      <>
                        {permission.includes("chatbot:create") || permission.includes("chatbot:delete") ? (
                          <>
                            {permission.includes("chatbot:create") && (
                              <Tooltip title="Edit" arrow>
                                <IconButton onClick={() => editRedirect(item.id)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {permission.includes("chatbot:delete") && (
                              <Tooltip title="Delete" arrow>
                                <IconButton onClick={() => deleteModal(item.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
              </TableCell> */}
            </TableRow>
          </>
        );
      });
    const getCustomerOnload = async () => {
      try {
        const response = await getTenantTemplateInfo(
        {
          id, 
          params: {
          limit: 10,
          page: 1,
          },
        }).unwrap();
        setPerPage(10);
        setCurrentPage(1);
        setCount(response?.data?.pagination?.totalTemplates);
        setCustomerInfo(response.data?.templates);
        setPaginationObject(response?.data?.pagination)
        if (checkedItems?.length > 0) {
          setCheckedItems([]);
        }
      } catch (error) {
        console.log(error);
      } finally { setIsChecked(false) }
    };
    const handleDiscount = async (values) => {
      const body = {
        customer_ids: checkedItems,
        discount: [
          {
            discount_product_name: "SIP Trunk",
            discount_type: values.sip_trunk ? values.sip_trunk : "Fixed",
            discount_value: Number(values.sip_trunk_margin),
          },
          {
            discount_product_name: "International DID",
            discount_type: values.international_did ? values.international_did : "Fixed",
            discount_value: Number(values.international_did_margin),
          },
          {
            discount_product_name: "National DID",
            discount_type: values.national_did ? values.national_did : "Fixed",
            discount_value: Number(values.national_did_margin),
          },
        ],
      };
      try {
        const response = await customerBulkDiscount(body).unwrap();
        suiSnackbar(response.message, "success");
        setOpen(false);
        setCheckedItems([]);
        discountFormik.handleReset();
      } catch (error) {
        suiSnackbar(error.data.message, "error");
      }
    };
    useEffect(() => {
      getCustomerOnload()
    }, []);
  
    const handleSearch = async (e) => {
      setSearchParams(e.target.value)
      if (e.target.value.length > 2) {
        setIsChecked(true)
        try {
          const response = await getTenantTemplateInfo( { id, params: {search: e.target.value, page: 1, limit: 10} })
          setCount(response?.data?.data?.pagination?.totalTemplates);
          setCustomerInfo(response?.data?.data?.templates);
          setPaginationObject(response?.data?.data?.pagination)
          setPerPage(10);
          setCurrentPage(1);
        } catch (error) {
          console.log(error)
        } finally { setIsChecked(false) }
      }
  
      if (e.target.value.length === 0) {
        setIsChecked(true)
        const response = await getTenantTemplateInfo({ id, params: {search: e.target.value, page: 1, limit: 10 }})
        setCustomerInfo(response?.data?.data?.templates)
        setCurrentPage(1)
        setCount(response?.data?.data?.pagination?.totalTemplates);
        setPaginationObject(response?.data?.data?.pagination)
        setIsChecked(false)
      }
    }
  
    return (
      <>
        <SuiBox my={3}>
          <Card sx={{ width: "100%" }}>
            <SuiBox sx={{display:'flex', alignItems:'center'}}>
              <Box 
                component={Link}
                to={"/tenant"}
                onClick={doCancel}>
              <ArrowBackIosIcon style={{width: "2em", height: "1.3em", marginLeft:"10px", marginRight:"-18px",marginTop:'6px' ,cursor:'pointer', color:'rgb(22, 23, 64)' }}/>
              </Box>
              
            <TableToolbar title={"Chatbot Template Listing"}>
            {/* <SuiButton
                    component={Link}
                    size="small"
                    buttonColor="info"P
                    sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                    to={"/tenant"}
                    onClick={doCancel}
                  >
                    Backb
            </SuiButton> */}
        
            </TableToolbar>    
            </SuiBox>
          
            <hr style={{ marginTop: 0, marginBottom: 0, borderColor: "#E9E9E966" }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }} m={2} >
              <SuiInput
                inputSettungs={{ autoComplete: "off" }}
                type="text"
                placeholder="Search"
                name="discount_type"
                value={searchParams}
                onChange={handleSearch}
                customClass='inputWithIcon'
                withIcon={{
                  baseClass: "outlined",
                  svg: <SearchIcon sx={{ color: '#999999', width: '16px', height: '14px', paddingRight: 0 }} />,
                  direction: "left",
                }}
              />
              {/* <div>
                {type === 'tenant' ?
                  <>
                    <SuiButton
                      component={Link}
                      size="small"
                      buttonColor="info"
                      sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                      to={"/chatbot-template/add-template"}
                      onClick={doCancel}
                    >
                      Add Template
                    </SuiButton>
                  </> : <>
                    {permission && permission?.includes('chatbot:create') &&
                      <>
                        <SuiButton
                          component={Link}
                          size="small"
                          buttonColor="info"
                          sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                          to={"/chatbot-template/add-template"}
                          onClick={doCancel}
                        >
                          Add Template
                        </SuiButton>
                      </>
                    }
                  </>}
  
              </div> */}
            </Box>
  
            <EnhancedTable
              // tableMinWidth={1000}
              tablehead={type === 'tenant' ? TenantChatBotTableHeader : TenantTemplateTableHeader}
              rowsData={rows}
              isLoading={isChecked}
              overFlow='hidden !important'
            />
            <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
              <DialogContent
                sx={{
                  fontWeight: "semiBold",
                }}
              >
                Are you sure you want delete this chatbot template
                <Grid container rowSpacing={2}>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Grid container justifyContent={"end"} spacing={2}>
                      <Grid item xs={12} sm="auto">
                        <SuiButton
                          size="small"
                          sx={{ px: "1rem" }}
                          onClick={() => setOpenModal(false)}
                          buttonColor="dark"
                          variant="outlined"
                          fullWidth
                        >
                          Cancel
                        </SuiButton>
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <SuiButton
                          size="small"
                          sx={{ px: "1rem" }}
                          buttonColor="info"
                          fullWidth
                          onClick={() => handleDeleteIconClick(deleteID)}
                        >
                          {deleteLoader ? <CircularProgress size="20px" color="inherit" /> : 'Delete'}
                        </SuiButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </SuiDialog>
  
            <DialogBoxForEncryptedCode open={openCodePreview} handleClose={() => setOpenCodePreview(false)} text={previewCodeDialogText} />
  
            {!isChecked && customerInfo?.length > 0 && (
              <Grid
                container
                p={3}
                spacing={1.5}
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "center", md: "space-between" }}
                alignItems="center"
              >
                <Grid item>
                  <SuiTypography fontWeight='light' fontSize={14} variant="h6" >{getPageRangeText(paginationObject)} </SuiTypography>
                </Grid>
                <Grid item>
                  <CommonPagination
                    mt={0}
                    totalData={count}
                    pageSize={perPage}
                    currentPage={Number(currentPage)}
                    onPageChange={handlePageChange}
                  />
                </Grid>
              </Grid>
            )}
          </Card>
  
        </SuiBox>
  
      </>
    );
  };
  
  export default TenantTemplateTable;
