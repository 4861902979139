export const TenantTableHeader = [
  {
    id: "1",
    align: "left",
    width: "10px",
    label: "",
  },
  {
    id: "2",
    align: "left",
    width: "150px",
    label: "Template Name",
  },
  {
    id: "4",
    align: "left",
    width: "450px",
    label: "Subscription Plan Name",
  },

  {
    id: "7",
    align: "left",
    width: "100px",
    label: "Action",
  }
];
export const TenantTemplateTableHeader = [
  // {
  //   id: "1",
  //   align: "left",
  //   width: "10px",
  //   label: "",
  // },
  
  {
    id: "2",
    align: "start",
    width: "50%",
    label: "Template Name",
  },
  {
    id: "4",
    align: "start",
    width: "50%",
    label: "Code",
  },
];
export const TenantChatBotTableHeader = [
  {
    id: "2",
    align: "left",
    width: "10px",
    label: "",
  },
  {
    id: "2",
    align: "left",
    width: "150px",
    label: "Template Name",
  },
  {
    id: "3",
    align: "left",
    width: "450px",
    label: "Code",
  },

  {
    id: "4",
    align: "left",
    width: "100px",
    label: "Action",
  }
];